import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LayoutNavbarDrawer from './pages/LayoutNavbarDrawer';
import Layout from './pages/Layout';
import NoPage from './pages/NoPage';
import Czujniki from './pages/Czujniki';
import Czujnik from './pages/Czujnik';
import Odczyty from './pages/Odczyty';
import Home from './pages/Home';
import Form from './pages/Form';
import FormLogin from './pages/FormLogin';
import NfcPage from './pages/NfcPage';

import logo from './logo.svg';
import './App.css';


function App() {

  //const { isOnline } = useNetworkStatus();

  const clearCacheData = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    //alert("Complete Cache Cleared");
};

clearCacheData();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutNavbarDrawer />}>
          <Route index element={<Home />} />
          <Route path="form" element={<Form />} />
          <Route path="czujniki" element={<Czujniki />} />
          <Route path="czujnik/:id" element={<Czujnik />} />
          <Route path="odczyty" element={<Odczyty />} />
          <Route path="form-login" element={<FormLogin />} />
          <Route path="nfc" element={<NfcPage />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
